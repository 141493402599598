import { createElement, useEffect, useState } from "react";
import AnalyticsConsentModal from "../../components/modals/AnalyticsConsentModal";
import SimpleButton from "../../components/buttons/SimpleButton";
import HelpScreenText from "../../components/HelpScreenText";
import Logo from "../../components/Logo";
import { HelpComponentType } from "../../navigation/HelpNavigator";
import modalState from "../../state-observables/modal/ModalState";
import openNewTab from "../../utils/open-new-tab";
import "./styles.css";
import { AppDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { appConfig } from "../../dbConfigs/configs";
import martyConnector from "../../MartyConnector/MartyConnector";
import ServiceProgramOnboardingModal from "../../components/modals/ServiceProgramOnboardingModal";
import { getEmailGivenSerialNumber, getSerialNumberStubbornly, isSerialNumberRegistered } from "../../ServiceProgram/onboarding";
import connectToMarty from "../../utils/network-api/connect-to-marty-function";
import ReportIssueModal from "../../components/modals/ReportIssueModal";
import LoadingSpinner from "../../components/LoadingSpinner";

interface HelpScreenProps extends HelpComponentType { }

export default function HelpScreen({ currentScreenSetter }: HelpScreenProps) {
  const [shouldShowUsersDashboardButton, setShouldShowUsersDashboardButton] = useState<boolean>(true);
  const [serialNumberEmail, setSerialNumberEmail] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    // Analytics
    const dbManager = DatabaseManager.getInstance();
    dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
      .then((db) => {
        (db as AppDatabase).storeVisitedScreen("help-and-support");
      }).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // check if the robot is registered in the service program 
    setShouldShowUsersDashboardButton(true);
    setIsLoading(true);
    if (martyConnector.martySerialNo) {
      isSerialNumberRegistered(martyConnector.martySerialNo).then((isRegistered) => {
        if (isRegistered) {
          // get the email associated with the serial number
          getEmailGivenSerialNumber(martyConnector.martySerialNo).then((email) => {
            if (email) {
              // open the dasbhoard as a webview
              setSerialNumberEmail(email);
              setShouldShowUsersDashboardButton(true);
            }
          });
        } else {
          setShouldShowUsersDashboardButton(isRegistered);
        }
      }).catch((err) => console.log(err)).finally(() => setIsLoading(false));
    } else {
      setShouldShowUsersDashboardButton(true);
      setIsLoading(false);
    }
  }, [martyConnector.martySerialNo]);


  const onServiceProgram = async () => {
    if (martyConnector.isConnected()) {
      showServiceProgramModal();
    } else {
      // first connect to marty
      connectToMarty(() => {
        const timeout = setTimeout(() => {
          // check if the service program modal is already open
          console.log("modalState.modalContent", modalState.modalTitle)
          if (modalState.modalContent) {
            return;
          }
          showServiceProgramModal();
          clearTimeout(timeout);
        }, 1000);
      })
    }
  }

  const showServiceProgramModal = () => {
    getSerialNumberStubbornly(() => martyConnector.systemInfo?.SerialNo, 5)
      .then((serialNumber) => {
        if (serialNumber) {
          const ServiceProgramModalWithProps = createElement(ServiceProgramOnboardingModal, {
            martySerialNumber: serialNumber
          })
          // openning modal if we have serial number and we should show onboarding modal
          modalState.setModal(ServiceProgramModalWithProps, "");
        }
      }).catch((err) => console.log(err));
  }

  return (
    <div className="help-screen-container">
      <div className="help-screen-logo-container">
        <Logo />
      </div>
      <div className="help-screen-main-text-container">
        <HelpScreenText />
      </div>
      <div className="help-screen-buttons-container">
        <SimpleButton
          onClick={() =>
            openNewTab("https://userguides.robotical.io/martyv2/support_faqs")
          }
          title="OPEN FAQ"
        />
        <SimpleButton
          onClick={() => openNewTab("https://codemarty.com/privacy-policy")}
          title="PRIVACY POLICY"
        />
        <SimpleButton
          onClick={() => modalState.setModal(createElement(AnalyticsConsentModal), "HELP US IMPROVE MARTY")}
          title="DATA ANALYTICS"
        />
        <SimpleButton
          onClick={() => currentScreenSetter("diagnostics")}
          title="CHECK DIAGNOSTICS"
        />
        <SimpleButton title="Issue Report" onClick={() => modalState.setModal(createElement(ReportIssueModal), "Issue Report")} />
        {isLoading ? <div style={{ width: 30, justifySelf: "center" }}> <LoadingSpinner /></div> : <>
          {shouldShowUsersDashboardButton
            ?
            <SimpleButton title="Warranty Service Dashboard" onClick={() => serialNumberEmail ? currentScreenSetter("warranty-dashboard", { userEmail: serialNumberEmail }) : openNewTab("https://service-program-dashboard.robotical.io/")} />
            :
            <SimpleButton title="Warranty Service Registration" onClick={onServiceProgram} />
          }</>}
      </div>
    </div>
  );
}
