import { useState } from 'react';
import styles from './styles.module.css';
import { secondaryModalState } from '../../../state-observables/modal/ModalState';
import { RICChannelWebBLE } from '@robotical/ricjs';
import { FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import AddMoreRobotsInfo from './AddMoreRobotsInfo';
import martyConnector from '../../../MartyConnector/MartyConnector';

function ServiceProgramAddMoreRobotsModal() {
  const [devices, setDevices] = useState<BluetoothDevice[]>([]);

  const scanForNewDevice = async () => {
    // get the name of the already connected device first
    const namesToIgnore = [...devices.map((device) => ({ name: device.name })), { name: martyConnector.RICFriendlyName }];

    const device = await navigator.bluetooth.requestDevice({
      // acceptAllDevices: true,
      filters: [
        { services: [RICChannelWebBLE.RICServiceUUID] },
      ],
      // @ts-ignore
      exclusionFilters: namesToIgnore,
      optionalServices: [],
    });
    // add the device to the list of devices only if it's not already there
    if (!devices.find((deviceInList) => deviceInList.id === device.id)) {
      setDevices((prevDevices) => [...prevDevices, device]);
      toast.success(`${device.name} added to the list of robots.`, {
        autoClose: 1000,
      });
    } else {
      toast.error(`${device.name} is already in the list of robots.`, {
        autoClose: 1000,
      });
    }
  };

  const removeDevice = (deviceId: string) => {
    setDevices((prevDevices) => prevDevices.filter((device) => device.id !== deviceId));
    toast.success(`Robot removed from the list.`, {
      autoClose: 1000,
    });
  };

  const onCancel = () => {
    secondaryModalState.closeModal([]);
  };

  const onSubmit = () => {
    secondaryModalState.closeModal(devices);
  };

  return (
    <div className={styles.container}>
      <AddMoreRobotsInfo />
      <ul className={styles.deviceList}>
        {devices.map((device) => (
          <li key={device.id} className={styles.deviceItem}>
            {device.name}
            <span className={styles.removeButton} onClick={() => removeDevice(device.id)}>
              <FaTrashAlt />
            </span>
          </li>
        ))}
      </ul>
      <div className={styles.buttonsContainer}>
        <button type="button" className={styles.submitButton} onClick={onSubmit}>Submit</button>
        <button type="button" className={styles.cancelButton} onClick={onCancel}>Cancel</button>
        <button type="button" className={styles.addAnotherRobotButton} onClick={scanForNewDevice}>Add Another Robot</button>
      </div>
    </div>
  );
};

export default ServiceProgramAddMoreRobotsModal;
