import React, { createElement, useEffect, useState } from "react";
import styles from "./styles.module.css";
import ms, {
  secondaryModalState,
} from "../../../state-observables/modal/ModalState";
import { appendSerialNumberToOnboardingCookie } from "../../../ServiceProgram/cookies";
import {
  getSerialNumberFromDevice,
  isSerialNumberRegistered,
  isUserRegistered,
} from "../../../ServiceProgram/onboarding";
import ServiceProgramAddMoreRobotsModal from "../ServiceProgramAddMoreRobotsModal";
import { FaSpinner } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import ServiceProgramInfo from "./ServiceProgramInfo";
import { toast } from "react-toastify";
import ServiceProgramComingSoonModal from "../ServiceProgramComingSoonModal";
import FirmwareVersionChekcer from "../../../utils/FirmwareVersionChecker";
import martyConnector from "../../../MartyConnector/MartyConnector";
import { useNavigate } from "react-router-dom";
import UserRole from "../../UserRole";
import { UserRole as UserRoleType } from "../../../store/user-role-context";
import EmailAlreadyRegisteredModal from "./EmailAlreadyRegisteredModal";
import WarrantyMailer from "../../../utils/warranty-service-mailer/WarrantyMailer";

type Props = {
  martySerialNumber: string;
};

function ServiceProgramOnboardingModal({ martySerialNumber }: Props) {
  const [email, setEmail] = useState("");
  const [school, setSchool] = useState("");
  const [otherSerialNumbers, setOtherSerialNumbers] = useState<string[]>([]);
  const [isLoadingMessage, setIsLoadingMessage] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const [modalContent, setModalContent] = useState<
    "user-selector" | "onboarding"
  >("user-selector");
  const navigate = useNavigate();

  useEffect(() => {
    // set onboarding cookie
    if (modalContent === "onboarding") {
      appendSerialNumberToOnboardingCookie(martySerialNumber);
      ms.modalTitle = "Warranty Service Registration";
    }
  }, [modalContent]);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // validation
    const typedEmail = e.target.value;
    const trimmedEmail = typedEmail.trim();
    setEmail(trimmedEmail);
  };

  const handleModalContentChange = (newModalContent: "user-selector" | "onboarding") => {
    ms.updateModalProps({ withCloseButton: false })
    setModalContent(newModalContent);
  };

  const onCancel = () => {
    ms.closeModal();
    // const feedbackTimout = setTimeout(() => {
    //   secondaryModalState.setModal(createElement(OnCancelOnoboardingModal), "We're asking for your feedback");
    // }, 1000);
    // return () => clearTimeout(feedbackTimout);
  };

  const onAddMoreRobots = async () => {
    setIsLoadingMessage("Scanning for more robots...");
    try {
      const devices = await secondaryModalState.setModal(
        createElement(ServiceProgramAddMoreRobotsModal),
        "Add more robots"
      );
      setIsLoadingMessage("Getting serial numbers...");
      setOtherSerialNumbers([]);
      for (const device of devices) {
        try {
          const serialNumber = await getSerialNumberFromDevice(device);
          // add serial number to list only if it's not same as martySerialNumber
          if (serialNumber && serialNumber !== martySerialNumber) {
            // also, make sure this serial number is not already registered
            const isSerialNumberInDatabase = await isSerialNumberRegistered(
              serialNumber
            );
            if (isSerialNumberInDatabase) {
              toast.warn(
                `Serial number ${serialNumber} is already registered. It will not be added to the list.`,
                { autoClose: 5000 }
              );
            } else {
              setOtherSerialNumbers((prevSerialNumbers) => [
                ...prevSerialNumbers,
                serialNumber,
              ]);
            }
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setIsLoadingMessage("");
  };

  const onSubmit = async () => {
    // submit email and school only if serial number is not already in the database
    // and only if the email is not already in the database
    if (email === "") {
      toast.error("Please enter a valid email address.", { autoClose: 5000 });
      return;
    }
    if (!agreeToTerms) {
      toast.error("You must agree to the warranty terms to use this service.", { autoClose: 5000 });
      return;
    }
    setIsLoadingMessage("Registering...");
    setTimeout(() => setIsLoadingMessage(""), 5000); // set isLoadingMessage to empty string after 5 seconds to make sure it doesn't stay forever
    const isSerialNumberInDatabase = await isSerialNumberRegistered(
      martySerialNumber
    );
    const isEmailInDatabase = await isUserRegistered(email);
    if (isSerialNumberInDatabase) {
      toast.error(
        <>
          {"This serial number is already registered. Please visit "}
          <a
            href="https://service-program-dashboard.robotical.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            your dashboard
          </a>
          {" to manage your robots."}
        </>,
        {
          autoClose: 5000,
        }
      );
      setIsLoadingMessage("");
      return;
    }
    if (isEmailInDatabase) {
      openEmailAlreadyRegisteredModal(email, [martySerialNumber, ...otherSerialNumbers]);
      // toast.error(
      //   <>
      //     {"This email is already registered. Please visit "}
      //     <a
      //       href="https://service-program-dashboard.robotical.io/"
      //       target="_blank"
      //       rel="noopener noreferrer"
      //     >
      //       your dashboard
      //     </a>
      //     {" to manage your robots."}
      //   </>,
      //   {
      //     autoClose: 5000,
      //   }
      // );
      setIsLoadingMessage("");
      return;
    }
    // send registration email
    const didSend = WarrantyMailer.sendRegistrationConfirmationEmail(email, school, [martySerialNumber, ...otherSerialNumbers]);
    if (!didSend) {
      toast.error("Something went wrong while sending the email. Please try again later.");
      setIsLoadingMessage("");
      return;
    }
    FirmwareVersionChekcer.checkVersion(martyConnector.martyVersion);
    toast.success(`Registration email sent to ${email}! To complete the registration, please click the link in the email.`, { autoClose: false });
    setIsLoadingMessage("");
    ms.closeModal(null, () => navigate("/"));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  const handleComingSoonClick = () => {
    secondaryModalState.setModal(
      createElement(ServiceProgramComingSoonModal),
      "Coming Soon!"
    );
    secondaryModalState.updateModalProps({ withCloseButton: true });
  };

  const handleUserRoleSelection = (role: UserRoleType) => {
    if (role === "teacher") {
      handleModalContentChange("onboarding");
    } else {
      ms.closeModal();
    }
    martyConnector.setUserRole(role);
  }
  
  const openEmailAlreadyRegisteredModal = (email: string, serialNumbers: string[]) => {
    secondaryModalState.setModal(
      createElement(EmailAlreadyRegisteredModal, { email, serialNumbers }),
      "Email Already Registered"
    );
  }


  return (
    <div className={styles.container}>
      {modalContent === "onboarding" ? (
        <>
          <h1>Warranty Service Program</h1>
          <ServiceProgramInfo />
          <button
            className={styles.comingSoonButton}
            onClick={handleComingSoonClick}
          >
            Coming Soon: Sneak Peek
          </button>
          <form onSubmit={handleSubmit} className={styles.form}>
            <label htmlFor="email" className={styles.label}>
              Email:
              <input
                type="email"
                id="email"
                className={styles.input}
                value={email}
                onChange={onEmailChange}
                required
              />
            </label>
            <label htmlFor="school" className={styles.label}>
              School/Establishment (if applicable):
              <input
                type="text"
                id="school"
                className={styles.input}
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              // required
              />
              <div className={styles.termsContainer}>
                <input
                  type="checkbox"
                  id="terms"
                  className={styles.checkbox}
                  checked={agreeToTerms}
                  onChange={(e) => setAgreeToTerms(e.target.checked)}
                  required
                />
                {" "}
                <label htmlFor="terms" className={styles.termsLabel}>
                  I agree to the{" "}
                  <a
                    href="https://marty-webapp.web.app/warranty-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Warranty Terms
                  </a>
                  .
                </label>
              </div>
            </label>
            <label htmlFor="serialNo" className={styles.label}>
              Serial Number(s):
              <input
                type="text"
                id="serialNo"
                className={`${styles.input} ${styles.disabledInput}`}
                value={martySerialNumber}
                disabled
                required
              />
              {otherSerialNumbers.map((serialNumber) => (
                <input
                  key={serialNumber}
                  type="text"
                  id="serialNo"
                  className={styles.input}
                  value={serialNumber}
                  disabled
                />
              ))}
            </label>
            {isLoadingMessage && (
              <div className={styles.scanningForMoreRobots}>
                <h5>
                  {isLoadingMessage} <FaSpinner />
                </h5>
              </div>
            )}
            <div className={styles.buttonContainer}>
              <button
                type="submit"
                className={[styles.submitButton, isLoadingMessage !== "" ? styles.disabledButton : ""].join(" ")}
                disabled={isLoadingMessage !== ""}
              >
                Submit
              </button>
              <button
                type="button"
                className={[styles.cancelButton, isLoadingMessage !== "" ? styles.disabledButton : ""].join(" ")}
                onClick={onCancel}
                disabled={isLoadingMessage !== ""}
              >
                Cancel
              </button>
              <ReactTooltip id="add-more-robots-tooltip" effect="solid" />
              <button
                disabled={isLoadingMessage !== ""}
                type="button"
                className={[styles.addMoreRobotsButton, isLoadingMessage !== "" ? styles.disabledButton : ""].join(" ")}
                onClick={onAddMoreRobots}
                data-tip={
                  "Add more robots associated with this email and school."
                }
                data-for={"add-more-robots-tooltip"}
              >
                Add more robots
              </button>
            </div>
          </form>
        </>
      ) : (
        <UserRole onUserRoleSelection={handleUserRoleSelection} isForWarrantyOnboarding={true}/>
      )}
    </div>
  );
}

export default ServiceProgramOnboardingModal;
